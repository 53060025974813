import { AgGridReact } from 'ag-grid-react';
import Papa, { ParseResult } from 'papaparse';
import {
  useCallback, useRef, useState,
} from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import styles from './BulkTagRegisterComponent.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import BaseTable from '../../components/BaseTable';
import AlertModal, {
  alertModalInitialState,
  CloseAlertModal,
  closeModalInitialState,
} from '../../components/AlertModal';
import MappingSelect from '../../components/MappingSelect';
import { useMessageModal } from '../../hooks/modal.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import Button from '../../components/Button/button';
import BaseText from '../../components/BaseText/BaseText';
import { useDocumentMaintenanceSearchApi } from '../../hooks/api/documentsMaintenance.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import Config from '../../config/config';

/**
 * ファイル状態 インターフェース
 */
export interface FileState {
  file: string
}

/**
 * 選択したオプション インターフェース
 */
export interface SelectedOption {
  changedKey: string,
  changedOption: string,
}

/**
 * 一括メンテ検索コンポーネント インターフェース
 */
export interface BulkTagsProps {
  bulkTagsFile: File;
  setBulkTagsFile: (display: File | null) => void;
  reloadSearchList: () => void;
}

const selectOptions = [
  { value: 'ignore', label: '(無視する)' },
  { value: 'search', label: '検索対象とする' },
];

/**
 * CSVページからの一括メンテ検索ページ
 */
function Component({ bulkTagsFile, setBulkTagsFile, reloadSearchList }: BulkTagsProps) {
  usePageTitle('一括メンテ検索');

  const gridRef = useRef<AgGridReact>(null);

  // hooks
  const openMessageModal = useMessageModal();

  // states
  const [parsedCsvData, setParsedCsvData] = useState<object[]>([]);
  const [objKeys, setObjKeys] = useState<string[]>([]);
  const [tagsToRegister, setTagsToRegister] = useState<any[]>([]);
  const [tagsToDisplay, setTagsToDisplay] = useState<any[]>([]);
  const [changedObject, setChangedObject] = useState<SelectedOption>({ changedKey: '', changedOption: '' });
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [columnRegistrations, setColumnRegistrations] = useState<string[]>([]);
  const [delaySearchListLoading, setDelaySearchListLoading] = useState(false);
  const [parsingCsvLoading, setParsingCsvLoading] = useState(false);

  // Apis
  const { request: documentMaintenanceSearchRequest } = useDocumentMaintenanceSearchApi();
  const { request: createLog } = useCreateLogApi();

  // methods
  const onCancel = useCallback(() => {
    setBulkTagsFile(null);
  }, [setBulkTagsFile]);

  const alertModalFunction = useCallback((text: string, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const getSearchItems = useCallback(() => columnRegistrations.map((columnName) => {
    const searchValues = tagsToRegister.map((tag) => tag[columnName]).filter(Boolean);
    return {
      searchName: columnName,
      searchValue: searchValues,
    };
  }), [columnRegistrations, tagsToRegister]);

  const onSave = useCallback(() => {
    if (columnRegistrations.length === 0) {
      setCloseAlertModal({
        ...closeAlertModal,
        open: true,
        text: '検索対象が選択されていません。',
        onCancel: () => { setCloseAlertModal({ ...closeAlertModal, open: false }); },
      });
    } else {
      const searchItems = getSearchItems();
      setAlertModal({
        open: true,
        text: `以下の項目にて検索処理を実施いたします。\n${columnRegistrations.map((item) => `\nー ${item}`)}`,
        onConfirm: () => {
          setAlertModal({ ...alertModal, open: false });
          try {
            documentMaintenanceSearchRequest({ searchList: searchItems });
          } catch (e) {
            console.log(e);
          }
          setDelaySearchListLoading(true);
          setTimeout(() => {
            reloadSearchList();
            setDelaySearchListLoading(false);
          }, 1000);
          alertModalFunction('検索を受け付けました。\n検索結果は一括メンテ結果一覧画面よりダウンロードできます。', () => {
            onCancel();
          });
        },
        onCancel: () => {
          setAlertModal({
            ...alertModal, open: false,
          });
        },
      });
    }
  }, [columnRegistrations, closeAlertModal, getSearchItems, alertModal, documentMaintenanceSearchRequest, alertModalFunction, reloadSearchList, onCancel, openMessageModal]);

  const getDynamicColumn = useCallback((array: object[]) => Object.keys(array[0]).map(((key) => {
    const columnWidth = key === Object.keys(array[0])[0] ? 80 : 150;
    return {
      field: key, headerName: key, cellClass: 'textFormat', width: columnWidth,
    };
  })), []);

  const generateColumns = useCallback((data: object[]) => {
    const lastColumn = {
      headerName: '', field: '', flex: 1, suppressColumnsToolPanel: true, resizable: true,
    };
    return [...getDynamicColumn(data), lastColumn];
  }, [getDynamicColumn]);

  const clearColumns = useCallback((data: object[]) => data
    .map((obj) => Object.entries(obj)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value.trim() }), {})), []);

  const maxCSVLines = Config.BulkTagMaintenance.maxCSVrecords;

  const isOverLimit = (totalLines: number): boolean => totalLines > maxCSVLines;

  const showAlert = (totalLines: number) => {
    setCloseAlertModal((prevState) => ({
      ...prevState,
      open: true,
      text: '検索条件CSVの読み込み上限数を超過しています。\nヘッダ行＋6万行までにしてください',
      onCancel,
    }));
  };

  const parseCsvFile = (file: File, agGridParams: GridReadyEvent) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: false,
      complete: (results: ParseResult<object>) => {
        const { data } = results;

        if (data.length === 0) {
          setParsingCsvLoading(false);
          return;
        }

        const clearColumnsData = clearColumns(data);

        if (!clearColumnsData || clearColumnsData.length === 0) {
          setParsingCsvLoading(false);
          return;
        }

        const dataWithNo = clearColumnsData.map((obj, index) => ({ no: index + 1, ...obj }));
        const allColumns = generateColumns(dataWithNo);
        agGridParams.api.setColumnDefs(allColumns);
        setParsedCsvData(dataWithNo);
        setObjKeys(Object.keys(data[0]));
        setTagsToRegister(dataWithNo);
        setTagsToDisplay(dataWithNo.slice(0, 5));
        setParsingCsvLoading(false);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
        setParsingCsvLoading(false);
      },
    });
  };

  const getTotalLines = async (file: File): Promise<number> => {
    const headerLine = 1;
    const text = await file.text();
    const lines = text.split(/\r\n|\n/);
    return lines.length - headerLine;
  };

  const parseFile = useCallback(async (fileToParse: File, agGridParams: GridReadyEvent) => {
    const contentTotalLines = await getTotalLines(fileToParse);

    if (isOverLimit(contentTotalLines)) {
      showAlert(contentTotalLines);
      return;
    }

    setParsingCsvLoading(true);
    parseCsvFile(fileToParse, agGridParams);
  }, [clearColumns, generateColumns]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    parseFile(bulkTagsFile, params);
  }, [bulkTagsFile, parseFile]);

  const onOptionSelected = useCallback((value: string, columnName: string) => {
    const valueFormatted = JSON.parse(value).value;
    setChangedObject({ changedOption: valueFormatted, changedKey: columnName });

    setColumnRegistrations((prev) => {
      if (valueFormatted === 'ignore') {
        return prev.filter((item) => item !== columnName);
      }
      return [...prev, columnName];
    });
  }, []);

  // Const
  const columnWidth = 150;
  const tableMinWidth = `${objKeys.length * 150 + 150}px`;

  // effects
  useEffectOnce(() => {
    createLog(LogFormName.BulkMaintenanceSearch, LogControlName.Show);
  });

  return (
    <div className={styles.modalContainer} role="presentation" onClick={() => setBulkTagsFile(null)}>
      <div className={styles.modal} role="presentation" onClick={(e) => e.stopPropagation()}>
        <div className={styles.mainFrame}>
          <div className={[styles.mainFrameHeader, mainStyles['pl-3']].join(' ')}>
            一括メンテ検索
          </div>
          <div className={styles.bodyContentTop}>
            <div className={[styles.text, styles.ml70px, mainStyles['mt-15px']].join(' ')}>
              取り込みデータ
              {'　'}
              （最初の5行）
            </div>
          </div>
          <div className={styles.mainFrameBody}>
            <div className={styles.bodyContent}>

              <div className={styles.bodyContentMain}>
                <div className={styles.bodyContentMainTable}>
                  <div className={mainStyles['mt-15px']} style={{ height: '100%' }}>
                    <BaseTable
                      tableRef={gridRef}
                      formName="userRegisterFromCsvTable"
                      rowData={tagsToDisplay}
                      onGridReady={onGridReady}
                      minWidthProp={tableMinWidth}
                    />
                  </div>
                  <div className={mainStyles['mt-10']}>
                    <BaseText>
                      読み込み件数
                      {parsedCsvData.length}
                      件
                    </BaseText>
                  </div>
                  <div className={styles.textBelowTable}>
                    <BaseText>
                      <span className={` ${mainStyles['text-danger']} }`}>※</span>
                      {' '}
                      マッピングした結果で検索結果が絞り込みされます。
                    </BaseText>
                    <BaseText>
                      <span className={` ${mainStyles['text-danger']} }`}>※</span>
                      {' '}
                      項目名は検索対象と合わせていただく必要があります。
                    </BaseText>
                  </div>
                </div>
                <div className={styles.bodyContentMainBottom}>
                  <div className={[styles.text, styles.ml70px, mainStyles['mt-15px'], styles.textBold].join(' ')}>
                    マッピング設定
                  </div>

                  <div className={[mainStyles['d-flex'], mainStyles['mt-20px'], styles.mr80px].join(' ')}>
                    <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}>
                      <span className={[mainStyles['mr-3'], styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                        CSV
                      </span>
                    </div>
                    <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                      <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px' }}>no</div>
                      {objKeys.map((key) => (
                        <div key={key} className={[styles.divBox].join(' ')} style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}>{key}</div>
                      ))}
                    </div>
                  </div>
                  <div className={mainStyles['d-flex']}>
                    <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                      <span className={[styles.downArrow, mainStyles['mr-4'], styles.w70px].join(' ')}>
                        ↓
                      </span>
                    </div>
                  </div>
                  <div className={[mainStyles['d-flex'], mainStyles['mt-10'], mainStyles['mb-20px'], styles.mr80px].join(' ')}>
                    <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                      <span className={[mainStyles['mr-3'], styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                        検索項目
                      </span>
                    </div>
                    <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                      <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px', backgroundColor: '#f2f2f2' }} />
                      {objKeys.map((key) => (
                        <MappingSelect
                          key={key}
                          objKey={key}
                          columnWidth={columnWidth}
                          onOptionSelected={onOptionSelected}
                          optionWasChanged={changedObject}
                          selectOptions={selectOptions}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
              <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
            </div>

          </div>
          <div className={styles.bodyFooter}>
            <Button color="lightGray" size="smaller" onClick={onCancel}>キャンセル</Button>
            <Button className={`${mainStyles['ml-1']}`} size="smaller" onClick={onSave}>
              検索
            </Button>
          </div>
        </div>
      </div>
      {(delaySearchListLoading || parsingCsvLoading) && <LoadingOverlay />}
    </div>
  );
}

export default Component;
