import { AgGridReact } from 'ag-grid-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef } from 'ag-grid-community';
import { DateTime } from 'luxon';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './wareHousingRequestScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import BaseTable from '../../components/BaseTable';
import { DocumentWarehousing, DocumentWarehousingStoreForm, TagList } from '../../services/http/documentWarehousing.api';
import DatePickerJp from '../../components/DatePickerJp';
import Input from '../../components/Input/input';
import Button from '../../components/Button/button';
import AlertModal, { alertModalInitialState, CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import routes from '../../utils/routes';
import { useDocumentWarehousingStoreApi } from '../../hooks/api/documentWarehousing.hook';
import Breadcrumb from '../../components/Breadcrumb';
import Formatter from '../../utils/formatters';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import AGUtils from '../../utils/ag-grid.utils';
import ExportLabelComponent from '../confirmedRequestListScreen/exportLabelComponent';
import ControlCode from '../../utils/controlCode';
import Validator from '../../utils/validators';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { WarehouseStatus } from '../../utils/warehouse.utils';

/**
 * 入庫依頼のプロップスインターフェース
 */
interface TableBtnCellRenderProps {
  /** 削除ボタンクリックハンドラー */
  onDeleteClick: (documentWarehousing: DocumentWarehousing) => void;
  /** 入庫依頼データ */
  data: DocumentWarehousing;
}

/**
 * テーブルセルレンダラーコンポーネント
 */
function TableBtnCellRender({ data, onDeleteClick }: TableBtnCellRenderProps) {
  return (
    <div>
      <button type="button" className={[mainStyles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onDeleteClick(data)}>削除</button>
    </div>
  );
}

/**
 * 入庫依頼ページ
 */
function Component() {
  usePageTitle('入庫依頼');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { documentWarehousingList }: any = state;
  const openMessageModal = useMessageModal();

  const gridRef = useRef<AgGridReact<DocumentWarehousing>>(null);

  // States
  const [documentWarehousingListState, setDocumentWarehousingListState] = useState<DocumentWarehousing[]>(documentWarehousingList);
  const [fromDate, setFromDate] = useState<DateTime | null>(null);
  const [form, setForm] = useState<DocumentWarehousingStoreForm>({});
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [labelExportDisplay, setLabelExportDisplay] = useState(false);
  const [sortedIdList, setSortedIdList] = useState<any>([]);

  // Apis
  const storeDocumentWarehousing = useDocumentWarehousingStoreApi();
  const { request: createLog } = useCreateLogApi();

  // Effects
  useEffect(() => {
    setDocumentWarehousingListState(documentWarehousingList);
    const selectedDocumentsIds = { documentList: [...documentWarehousingList.map((document: DocumentWarehousing) => ({ documentId: document.documentId }))] };
    setForm(selectedDocumentsIds);
  }, []);

  // Methods
  const onDeleteDocumentWarehousingClick = useCallback((documentWarehousing: DocumentWarehousing) => {
    const newDocumentWarehousingList = documentWarehousingListState.filter((item: DocumentWarehousing) => item.documentId !== documentWarehousing.documentId);
    setDocumentWarehousingListState(newDocumentWarehousingList);
    const selectedDocumentsIds = { documentList: [...newDocumentWarehousingList.map((document) => ({ documentId: document.documentId }))] };
    setForm(selectedDocumentsIds);
  }, [documentWarehousingListState]);

  const handleSendForm = useCallback(async () => {
    if (form.trdContact && form.trdContact.length > Validator.contactMessageMax) {
      openMessageModal(`連絡事項を${Validator.contactMessageMax}文字以内で入力してください`);
      return;
    }
    if (form.emergencyContacts === undefined || form.scheduledDate === undefined) {
      openMessageModal('全ての必須項目を入力してください');
      return;
    }
    try {
      await storeDocumentWarehousing.request(form);
      const isDocumentNotWarehoused = documentWarehousingListState.some((documentWarehousing: DocumentWarehousing) => documentWarehousing.warehouseStatus === WarehouseStatus.NOT_RECEIVED);
      if (isDocumentNotWarehoused) {
        setAlertModal({
          open: true,
          text: '依頼が完了しました。\n保管品ラベルを発行しますか？',
          onCancel: () => {
            setAlertModal({ ...alertModal, open: false });
            navigate(routes.main);
          },
          onConfirm: () => {
            setAlertModal({ ...alertModal, open: false });
            setLabelExportDisplay(true);
          },
        });
      } else {
        setCloseAlertModal({
          ...alertModal,
          text: '依頼が完了しました。',
          open: true,
          onCancel() {
            setCloseAlertModal({ ...alertModal, open: false });
            navigate(routes.main);
          },
        });
      }
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [storeDocumentWarehousing, form, documentWarehousingListState, alertModal, navigate]);

  const handleSortedData = (data: DocumentWarehousing[]) => {
    const ids = data.map((d) => d.documentId);
    setSortedIdList(ids);
  };

  // Memos
  const colDefs = useMemo((): ColDef<DocumentWarehousing>[] => [
    AGUtils.colDefault('ccControlNumber', '文書ID'),
    AGUtils.colDefault('documentName', '文書名', 250),
    AGUtils.colDefault('documentTypeName', '文書種類', 130),
    AGUtils.colDefault('documentFileName', 'ファイル名', 170),
    AGUtils.colFileSize('documentFileSize', 'サイズ'),
    AGUtils.colUsername('registUser', '登録者'),
    AGUtils.colDate('registDate', '登録日時'),
    AGUtils.colUsername('updateUser', '更新者'),
    AGUtils.colDate('updateDate', '更新日時'),
    AGUtils.colDefault('warehouseStatus', '預け入れステータス', 180),
    AGUtils.colDefault('barcodePrinting', 'バーコード印字欄'),
    AGUtils.colDefault('itemCode', '保管品バーコード'),
    {
      field: 'tagList',
      headerName: 'タグ',
      resizable: true,
      minWidth: 130,
      flex: 1,
      cellClass: 'textFormat',
      valueGetter: ({ data }) => {
        const value = data?.tagList;
        if (!value) return '';
        const tagLabels = value.map((tag: TagList) => tag.tagLabel);
        return tagLabels.join(', ');
      },
    },
    {
      field: 'buttons',
      headerName: '',
      resizable: true,
      suppressColumnsToolPanel: true,
      width: 80,
      cellRenderer: TableBtnCellRender,
      cellRendererParams: {
        onDeleteClick: onDeleteDocumentWarehousingClick,
      },
    },
  ], [documentWarehousingListState]);

  useEffectOnce(() => {
    createLog(LogFormName.WareHousingRequestScreen, LogControlName.Show);
  });

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={styles['mainframe-body']}>
            <header>
              入庫
            </header>
            <div className={styles['table-container']}>
              <BaseTable<DocumentWarehousing>
                tableRef={gridRef}
                formName="warehousingRequestTable"
                rowData={documentWarehousingListState}
                columnDefs={colDefs}
                handleSortedData={handleSortedData}
                createLogOnDownloadTableData={LogFormName.WareHousingRequestScreen}
              />
            </div>
            <div className={styles.wrapper}>
              <div className={[mainStyles.text, mainStyles['text-danger'], mainStyles['mt-20px']].join(' ')}>
                <span className={mainStyles['mr-1']}>※</span>
                {' '}
                新規預け入れの文書には依頼確定後に発行される保管品バーコードを貼り付けてください
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>倉庫到着予定日</label>
                  <span>*</span>
                </div>
                <DatePickerJp
                  parentDivClassName={styles['w-100']}
                  inputClassName={styles['w-100']}
                  shouldDisableDate={(date) => date <= DateTime.local().startOf('day')}
                  value={fromDate}
                  onChange={(newValue) => {
                    setForm({ ...form, scheduledDate: newValue ? Formatter.fromDateTimeToString(newValue, Formatter.defaultFullDateFormat) : undefined });
                    setFromDate(newValue);
                  }}
                />
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>寺田倉庫への連絡事項</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.trdContact}
                  placeholder="100文字以内で入力してください"
                  onChange={(e) => setForm({ ...form, trdContact: e.target.value })}
                />
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                <div className={styles['label-container']}>
                  <label>緊急連絡先</label>
                  <span>*</span>
                </div>
                <Input
                  type="text"
                  className={mainStyles.input}
                  value={form.emergencyContacts || undefined}
                  onChange={(e) => setForm({ ...form, emergencyContacts: e })}
                />
                <div className={[mainStyles['text-danger'], mainStyles['ml-20px']].join(' ')}>
                  <span className={mainStyles['mr-1']}>※</span>
                  日中に連絡可能な連絡先をご記入ください
                </div>
              </div>
              <div>
                <div className={styles['label-container']}>
                  <label>お客様メモ</label>
                </div>
                <textarea
                  className={mainStyles['text-area']}
                  defaultValue=""
                  value={form.userMemo}
                  onChange={(e) => setForm({ ...form, userMemo: e.target.value })}
                />
              </div>
            </div>
          </div>
          <footer className={styles['body-footer']}>
            <Button color="lightGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
            <Button
              size="smaller"
              onClick={handleSendForm}
              loading={storeDocumentWarehousing.loading}
              disabled={!documentWarehousingListState.length}
            >
              依頼
            </Button>
          </footer>
          <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
          <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
          {labelExportDisplay && (
            <ExportLabelComponent
              sortedIdList={sortedIdList}
              returnToMain
              exportFrom={ControlCode.Receiving}
              data={documentWarehousingList}
              display={labelExportDisplay}
              setModalDisplay={setLabelExportDisplay}
              tableName="warehousingRequestTable"
            />
          )}
        </div>
)}
    />
  );
}

export default Component;
